<template>
  <section class="grid adb-page">
    <div class="col-12 md:col-4 adb-page-panel">
      <Tree
        :value="treeNodes"
        v-model:selectionKeys="selectedDatabases"
        selectionMode="checkbox"
        class="w-full"
      />
    </div>
    <div class="col-12 md:col-8">
      <FileUpload
        id="adb-file-upload"
        accept=".sql"
        :file-limit="1"
        :show-cancel-button="false"
        :show-upload-button="false"
        choose-label="Cargar archivo"
        choose-icon="pi pi-cloud-upload"
        @select="({ files }) => (file = files[0])"
        @remove="file = null"
      >
        <template #empty>
          <p class="text-center text-blue-300 text-xl">
            <i class="pi pi-info-circle text-xl"></i>
            <span class="block"
              >Arrastra y suelta un archivo SQL aquí o haz clic para seleccionar
              uno.</span
            >
          </p>
        </template>
      </FileUpload>
      <Button label="Crear esquema" @click="handleCreateSchema" />
      <div class="mt-3 shadow-5 p-2 bg-white border-round">
        <DataTable
          :value="scriptLogs"
          class="w-full bg-white"
          headerClass="bg-white"
        >
          <Column field="statusCode" header="Código" />
          <Column field="database" header="Base de datos" />
          <Column field="date" header="Fecha" />
          <Column field="message" header="Mensaje" />
          <template #footer>
            <div class="text-right">
              <Button
                severity="info"
                v-if="isLogs"
                label="Limpiar"
                icon="pi pi-trash"
                class="mr-2 p-button-warning"
                @click="clear"
              />
              <Button
                label="Ejecutar"
                icon="pi pi-play"
                class="p-button-success"
                @click="execute"
              />
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import {
  generateNewSchema,
  getErpDatabases,
  runErpDatabaseScript,
} from "@/services/erpDatabaseAdministrator.service";
import Tree from "primevue/tree";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Swal from "sweetalert2";

const store = useStore();
const toast = useToast();
const state = ref({});
const selectedDatabases = ref({});
const scriptLogs = ref([]);
const file = ref(null);

onMounted(async () => {
  store.commit("setIsLoading", true);
  const { status, data } = await getErpDatabases();
  store.commit("setIsLoading", false);
  if (status === 200) {
    state.value = data;
  }
});

const treeNodes = computed(() => {
  const servers = Object.keys(state.value);
  return servers.map((server) => {
    return {
      key: `server:${server}`,
      label: server,
      icon: "pi pi-server",
      children: state.value?.[server]?.map((database) => {
        return {
          key: `${server},${database}`,
          label: database,
          icon: "pi pi-database",
        };
      }),
    };
  });
});

const selectedItems = computed(() => {
  const items = Object.keys(selectedDatabases.value).filter((key) =>
    key.includes(",")
  );
  return items.map((item) => {
    const [server, database] = item.split(",");
    return { server, database };
  });
});

const isLogs = computed(() => scriptLogs.value.length > 0);
const yearsSchema = computed(() => {
  const currentYear = new Date().getFullYear();
  const baseYear = 2015;
  return Array.from(
    { length: currentYear - baseYear + 2 },
    (_, i) => baseYear + i
  );
});

const clear = () => {
  scriptLogs.value = [];
};

const execute = async () => {
  if (!file.value) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Debes seleccionar un archivo SQL",
      life: 3000,
    });
    return;
  }

  if (!selectedItems.value.length) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Debes seleccionar al menos una base de datos",
      life: 3000,
    });
    return;
  }

  store.commit("setIsLoading", true);
  const {
    status,
    message,
    data: errors,
  } = await runErpDatabaseScript(file.value, selectedItems.value);
  store.commit("setIsLoading", false);
  scriptLogs.value = errors ?? [];

  if (status != 200) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 5000,
    });
    return;
  }

  Swal.fire({
    title: "¡Éxito!",
    text: message,
    icon: "success",
    confirmButtonText: "Aceptar",
  });
};

const handleCreateSchema = async () => {
  const options = yearsSchema.value.map((year) => {
    return `<option value="${year}">${year}</option>`;
  });

  const response = await Swal.fire({
    title: "Indique el año del esquema a generar",
    html: `
      <select id="yearSelect" class="p-inputtext p-component w-full">
        <option value="">Seleccione un año</option>
        ${options.join("")}
      </select>
    `,
    showCancelButton: true,
    confirmButtonText: "Generar",
    cancelButtonText: "Cancelar",
    preConfirm: () => {
      const year = document.getElementById("yearSelect").value;
      if (!year) Swal.showValidationMessage("Por favor seleccione un año");
      return year;
    },
  });

  if (!response.isConfirmed) return;

  store.commit("setIsLoading", true);
  const blobData = await generateNewSchema(response.value);
  store.commit("setIsLoading", false);

  if (!blobData) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "No se pudo generar el esquema",
      life: 5000,
    });
    return;
  }
  const url = window.URL.createObjectURL(blobData);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `periodo-${response.value}.sql`);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);

  Swal.fire({
    title: "¡Éxito!",
    text: "Esquema generado correctamente",
    icon: "success",
    confirmButtonText: "Aceptar",
  });
};
</script>
<style lang="scss">
.adb-page {
  margin: 0px !important;
  width: 100%;
  height: calc(100vh - 69px);

  @media (min-width: 768px) {
    height: calc(100vh - 95px);
  }

  .adb-page-panel {
    height: 100%;
    background: #fff;
    overflow-y: auto;
  }
}

#adb-file-upload {
  .p-fileupload-buttonbar {
    padding: 0.3em;

    .p-fileupload-choose {
      width: 170px !important;
    }
  }

  .p-fileupload-content {
    padding: 0.4rem;
  }
}
</style>
